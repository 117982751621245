import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";

import {
  type TExpert,
  useGetMeetingsExpertsQuery,
  useGetMeetingsPatientsQuery,
} from "../../../api";
import { useUser } from "../../../common/user";
import { useIntlDocumentTitle } from "../../../hooks/use-document-title";
import { useScript } from "../../../hooks/use-script";
import type { TUser } from "../../../reducers/login";
import { formatPersonName } from "../../common/PersonName";
import { RemoteLogErrorBoundary } from "../../common/remote-log-error-boundary";
import { Button } from "../../ui/button";
import { Img } from "../../ui/image";
import { Layout } from "../../ui/layout";
import { Portlet, PortletTitle } from "../../ui/portlet";
import expertNoAvatarImg from "./expert-no-avatar.png";

const API_MEETINGS_IMAGE = (expert_id: TUser["account_id"], filename: string) =>
  `${process.env.API}/meetings/${expert_id}/images/${filename}`;

export default function MeetingsPage() {
  useIntlDocumentTitle("meetings.title");

  const [selectedExpert, setSelectedExpert] = useState<TExpert | null>(null);
  const goBack = useCallback(() => setSelectedExpert(null), []);

  return (
    <Layout contentClassName="max-sm:-tw-mx-2 max-sm:-tw-my-6">
      <Portlet as="main">
        <PortletTitle iconClassName="icon-calendar">
          <FormattedMessage id="meetings.title" />
        </PortletTitle>

        <RemoteLogErrorBoundary component="meetings-page">
          <div className="portlet-body">
            {selectedExpert ? null : <ExpertsList onExpertClick={setSelectedExpert} />}
            {selectedExpert ? <CabinetWidget expert={selectedExpert} onBackClick={goBack} /> : null}
          </div>
        </RemoteLogErrorBoundary>
      </Portlet>
    </Layout>
  );
}

function ExpertsList({ onExpertClick }: { onExpertClick(expert: TExpert): void }) {
  const expertsQuery = useGetMeetingsExpertsQuery();

  if (expertsQuery.isUninitialized || expertsQuery.isLoading) {
    return (
      <ul role="list" className="tw-flex tw-list-none tw-flex-col tw-gap-4 tw-px-0">
        {Array.from({ length: 6 }, (_, index) => (
          <Expert key={index} expert={undefined} onExpertClick={onExpertClick} />
        ))}
      </ul>
    );
  }

  if (expertsQuery.isError) {
    return (
      <div className="tw-space-y-4 tw-p-3.5 tw-text-center">
        <strong className="tw-text-base tw-text-red-500">
          <FormattedMessage id="meetings.error" />
        </strong>

        <p className="tw-my-0 tw-text-gray-400">
          <FormattedMessage id="events.error.description" />
        </p>
      </div>
    );
  }

  const noExpertsAvailable = expertsQuery.data.length == 0;

  if (noExpertsAvailable) {
    return (
      <div className="tw-space-y-4 tw-p-3.5 tw-text-center">
        <strong className="tw-text-base">
          <FormattedMessage id="meetings.not.found" />
        </strong>

        <p className="tw-my-0 tw-text-gray-400">
          <FormattedMessage id="meetings.not.found.description" />
        </p>
      </div>
    );
  }

  return (
    <ul role="list" className="tw-flex tw-list-none tw-flex-col tw-gap-4 tw-px-0">
      {expertsQuery.data.map((expert) => (
        <Expert key={expert.account_id} expert={expert} onExpertClick={onExpertClick} />
      ))}
    </ul>
  );
}

function Expert({
  expert,
  onExpertClick,
}: {
  expert: TExpert | undefined;
  onExpertClick(expert: TExpert): void;
}) {
  const description = expert ? getDescription(expert.account_id) : "";

  const imageLoadingElement = <Skeleton width={75} height={75} className="tw-rounded-full" />;

  const imageElement = expert ? (
    <Img
      width={75}
      height={75}
      errorFallback={
        <Img
          width={75}
          height={75}
          errorFallback={null}
          loadingFallback={imageLoadingElement}
          src={expertNoAvatarImg}
        />
      }
      loadingFallback={imageLoadingElement}
      src={expert.avatar ? API_MEETINGS_IMAGE(expert.account_id, expert.avatar) : expertNoAvatarImg}
      alt=""
    />
  ) : (
    imageLoadingElement
  );

  const nameElement = expert ? (
    <span>
      {formatPersonName({ person: expert, useMiddleName: true })}
      {description ? (
        <span className="tw-block tw-text-xs tw-text-gray-400">{description}</span>
      ) : null}
    </span>
  ) : (
    <Skeleton
      className="tw-rounded-md"
      containerClassName="tw-space-y-1.5"
      count={2}
      width={200}
      height={15}
    />
  );

  const buttonElement = expert ? (
    <Button rounded className="tw-uppercase" onClick={() => onExpertClick(expert)}>
      <FormattedMessage id="meetings.book" />
    </Button>
  ) : (
    <Skeleton className="tw-rounded-md" width={120} height={36} style={{ lineHeight: "20px" }} />
  );

  return (
    <li>
      <div className="sm:tw-hidden">
        <div className="tw-flex tw-items-center tw-gap-8">
          {imageElement}

          <div className="tw-flex tw-flex-col tw-gap-4">
            {nameElement}

            <div className="tw-grow-0">{buttonElement}</div>
          </div>
        </div>

        <hr className="tw-mb-0 tw-mt-4" />
      </div>

      <div className="tw-hidden tw-items-center tw-justify-between sm:tw-flex">
        <div className="tw-flex tw-items-center tw-gap-3">
          {imageElement}
          {nameElement}
        </div>

        {buttonElement}
      </div>
    </li>
  );
}

// FIXME: you have to click on back button twice to get back to all experts, due to onclick removed
// to not go to all experts when you go back from form to calendar view.
function CabinetWidget({ expert, onBackClick }: { expert: TExpert; onBackClick(): void }) {
  const user = useUser();
  const containerRef = useRef<HTMLDivElement>(null);
  const scriptStatus = useScript("https://cabinet.fm/external/widget.js");
  const patientsQuery = useGetMeetingsPatientsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  useEffect(() => {
    const isCabinetWidgetScriptLoaded = typeof Cabinet != "undefined";
    if (!isCabinetWidgetScriptLoaded) {
      return;
    }

    let observer: MutationObserver | undefined;
    let backButton: HTMLDivElement | undefined;

    function handleBackButtonClick(event: MouseEvent) {
      event.stopPropagation();
      onBackClick();
    }

    if (typeof MutationObserver != "undefined") {
      observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type == "childList" && mutation.addedNodes.length > 0) {
            const addedNode = mutation.addedNodes[0];

            if (addedNode instanceof HTMLElement) {
              addedNode.querySelectorAll("button").forEach((button) => {
                if (isSelectButton(button)) {
                  button.click();
                } else {
                  changeContinueButton(button);
                  changeBookButton(button);
                }
              });
            }

            if (addedNode instanceof HTMLButtonElement) {
              changeBookButton(addedNode);
            }

            if (addedNode instanceof HTMLDivElement) {
              changeBookText(addedNode);

              if (isBackButton(addedNode)) {
                if (backButton) {
                  onBackClick();
                } else {
                  backButton = addedNode;
                  backButton.addEventListener("click", handleBackButtonClick);
                }
              }

              if (isSuccessScreen(addedNode)) {
                addedNode.appendChild(createBackBtn(onBackClick));
              }
            }

            if (addedNode instanceof HTMLFormElement) {
              fillInForm(addedNode, user, expert);

              if (backButton) {
                backButton.removeEventListener("click", handleBackButtonClick);
              }
            }
          }
        }
      });

      if (containerRef.current) {
        observer.observe(containerRef.current, { subtree: true, childList: true });
      }
    }

    Cabinet.init({
      container: "#cabinet-container",
      company: parseInt(process.env.CABINET_COMPANY, 10),
      id: parseInt(expert.link, 10),
      locale: "ru",
    });

    return () => {
      observer?.disconnect();
    };
  }, [scriptStatus, user, expert, onBackClick]);

  return (
    <>
      <div
        id="cabinet-container"
        ref={containerRef}
        style={
          {
            "--cb-primary-bg": "#32c5d2",
            "--cb-primary-bg-hover": "#26a1ab",
            "--cb-shadow": "none",
          } as React.CSSProperties
        }
      ></div>

      <datalist id="patients">
        {patientsQuery.data?.map((patient) => (
          <option key={patient.id} value={patient.id}>
            {patient.name}
          </option>
        ))}
      </datalist>
    </>
  );
}

function getDescription(expertId: TUser["account_id"]): string {
  const MOLOTOVA_ACCOUNT_ID = 3320;
  const SOLOP_ACCOUNT_ID = 3342;

  switch (expertId) {
    case MOLOTOVA_ACCOUNT_ID:
      return "ДЕТИ";
    case SOLOP_ACCOUNT_ID:
      return "ДВНЧС";
    default:
      return "";
  }
}

function createBackBtn(onClick: () => void): HTMLButtonElement {
  const btn = document.createElement("button");
  btn.textContent = "Записаться к другому эксперту";
  btn.className =
    "tw-bg-primary tw-text-white tw-border-none tw-px-4 tw-py-2 tw-uppercase " +
    "tw-text-xs hover:tw-bg-primary-hover tw-transition-colors tw-duration-250 " +
    "tw-mx-auto tw-block tw-mb-4 -tw-mt-4";
  btn.onclick = onClick;
  return btn;
}

function isSelectButton(button: HTMLButtonElement) {
  return button.textContent?.toLowerCase() == "выберите";
}

function isSuccessScreen(div: HTMLDivElement): boolean {
  for (const textNode of textNodes(div)) {
    if (textNode.textContent && /поздравляем/i.test(textNode.textContent)) {
      return true;
    }
  }
  return false;
}

function changeContinueButton(button: HTMLButtonElement) {
  if (button.textContent && /продолжить/i.test(button.textContent)) {
    // We perform a replace because continue button's textContent includes an arrow on mobile screens.
    button.textContent = button.textContent.replace(/продолжить/i, "Записаться");
  }
}

function changeBookButton(button: HTMLButtonElement) {
  button.querySelectorAll("span").forEach((span) => {
    if (span.textContent?.toLowerCase() == "забронировать") {
      span.textContent = "Записаться";
    }
  });
}

function changeBookText(div: HTMLDivElement) {
  for (const textNode of textNodes(div)) {
    if (!textNode.textContent) {
      continue;
    }
    if (/бронирования/i.test(textNode.textContent)) {
      textNode.textContent = textNode.textContent.replace(/бронирования/i, "записи");
    } else if (/Бронирование прошло/i.test(textNode.textContent)) {
      textNode.textContent = textNode.textContent.replace(/Бронирование прошло/i, "Запись прошла");
    } else if (/сотрудника/i.test(textNode.textContent)) {
      textNode.textContent = textNode.textContent.replace(/сотрудника/i, "эксперта");
    }
  }
}

function fillInForm(form: HTMLFormElement, user: TUser, expert: TExpert): void {
  for (const input of form.querySelectorAll("input")) {
    if (input.type == "tel") {
      changeInputValue(input, user.phone);
    } else if (input.type == "email") {
      changeInputValue(input, user.email);
    } else if (testInputLabel(input, "Имя Фамилия")) {
      changeInputValue(input, formatPersonName({ person: user, useMiddleName: true }));
    } else if (testInputLabel(input, "ID доктора")) {
      changeInputValue(input, String(user.account_id));
      hideInput(input);
    } else if (testInputLabel(input, "ID пациента")) {
      input.setAttribute("list", "patients");
    } else if (testInputLabel(input, "ID эксперта")) {
      changeInputValue(input, String(expert.account_id));
      hideInput(input);
    }
  }
  for (const textarea of form.querySelectorAll("textarea")) {
    if (testInputLabel(textarea, "Комментарий")) {
      hideInput(textarea);
    } else if (testInputLabel(textarea, "Вопрос по пациенту")) {
      textarea.rows = 5;
    }
  }
}

/**
 * @see https://stackoverflow.com/a/46012210
 */
function changeInputValue(input: HTMLInputElement, value: string): void {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
  )?.set;
  nativeInputValueSetter?.call(input, value);

  const event = new Event("input", { bubbles: true });
  input.dispatchEvent(event);
}

function testInputLabel(input: HTMLInputElement | HTMLTextAreaElement, label: string): boolean {
  if (!input.parentElement) {
    return false;
  }

  for (const textNode of textNodes(input.parentElement)) {
    if (textNode.textContent == label) {
      return true;
    }
  }

  return false;
}

function* textNodes(element: HTMLElement): Generator<Node> {
  const nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT);

  for (let textNode; (textNode = nodeIterator.nextNode()); ) {
    yield textNode;
  }
}

function hideInput(input: HTMLInputElement | HTMLTextAreaElement): void {
  if (!input.parentElement) {
    return;
  }

  input.parentElement.className = "tw-invisible tw-absolute";
  input.readOnly = true;

  if (input instanceof HTMLInputElement) {
    input.type = "hidden";
  }
}

function isBackButton(addedNode: HTMLDivElement): boolean {
  return addedNode.textContent == "← Назад";
}
